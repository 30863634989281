"use strict"

import $ from 'jquery'
import 'bootstrap'
import Typed from 'typed.js'
import 'jquery.easing'
import '../scss/main.scss'

window.jQuery = $
window.$ = $

$(document).ready(function() {

  var nav = $('#mainNav')
  var navHeight = nav.outerHeight()

  $('.navbar-toggler').on('click', function() {
    if(!nav.hasClass('navbar-reduce')) {
      nav.addClass('navbar-reduce')
    }
  })

  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow')
    } else {
      $('.back-to-top').fadeOut('slow')
    }
  })

  $('.back-to-top').click(function() {
    $('html, body').animate({scrollTop : 0}, 1500, 'easeInOutExpo')
    return false
  });

  /*
  // Star Scrolling nav
  $('a.js-scroll[href*="#"]:not([href="#"])').on('click', function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - navHeight + 5)
        }, 1000, 'easeInOutExpo')
        return false
      }
    }
  })

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#mainNav',
    offset: navHeight
  })
  */

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll').on('click', function () {
    $('.navbar-collapse').collapse('hide')
  })

  // Navbar menu reduce
  $(window).on('scroll', function () {
    var pixels = 50
    if ($(window).scrollTop() > pixels) {
      $('.navbar-expand-md').addClass('navbar-reduce')
      $('.navbar-expand-md').removeClass('navbar-trans')
    } else {
      $('.navbar-expand-md').addClass('navbar-trans')
      $('.navbar-expand-md').removeClass('navbar-reduce')
    }
  })

  // Start Typed
  if ($('.text-slider').length == 1) {
    var typed_strings = $('.text-slider-items').text();
    var typed = new Typed('.text-slider', {
      strings: typed_strings.split(','),
      typeSpeed: 60,
      loop: false,
      //backDelay: 1100,
      //backSpeed: 30,
      fadeOut: true,
      showCursor: false
    });
  }

  // Add the active class in the main menu
  $('#mainNav a[href="' + location.pathname + '"]').parent().addClass('active')
});
